@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.bg_main {
  height: 89vh;
  background-color: #EFF0FF;
  padding: 50px;
  position: relative;
}

.login_main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 30%;
}

.login_main .title {
  font-size: 26px;
  margin: 0px 10px 10px 0px;
  color: #415aa9;
}

.text_input {
  width: 100%;
}

.text_input input {
  width: 100%;
}

/* ==>>> Layout <<<== */

.layout_main {
  display: flex;
}

.layout_inner {
  display: flex;
  flex-direction: column;
}

/* ==>>> Sidebar <<<== */

.sidebar_link_main:hover {
  background: #EBDEF7 !important;
}

.sidebar_link_main_active {
  background: #E8DEF8 !important;
}

.sidebar_link span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.MuiDrawer-paperAnchorLeft {
  background: linear-gradient(0deg, rgba(111, 75, 167, 0.08), rgba(111, 75, 167, 0.08)), #FEF8FC !important;
  border: none !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

.MuiToolbar-regular {
  background: linear-gradient(0deg, rgba(111, 75, 167, 0.08), rgba(111, 75, 167, 0.08)), #FEF8FC !important;
}

.hamburger_menu {
  background-color: #6C48A3 !important;
}

.layout_children_main {
  padding: 70px 20px 20px 20px !important;
  height: 89.3vh;
  overflow: auto;
  background-color: #FFFBFE;
}

/* ==>>> common <<<== */

.contained_button_add {
  background-color: #6750A4 !important;
  color: #FFFFFF !important;
  padding: 10px 24px !important;
  border: none !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  border-radius: 50px !important;
  height: 40px;
}

.contained_button_cancel {
  background: #E7E1E6 !important;
  color: #948F94 !important;
  padding: 10px 24px !important;
  border: none !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  border-radius: 50px !important;
  height: 40px;
}

.outlined_button {
  border: 1px solid#6750A4 !important;
  color: #6750A4 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: sans-serif !important;
  width: 100%;
}

.outlined_button:hover {
  background-color: #E8DEF8 !important;
  border: 1px solid #CCC2DC !important;
  color: #4A4458 !important;
}

.main_card {
  background-color: #FFFFFF;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px;
  border-radius: 5px;
}

.card_footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_header_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #6C48A3;
}

.input_label {
  color: #7A767A !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
}

.file_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file_label {
  color: #7A767A !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  margin-top: 10px !important;
}

.divider {
  margin: 10px 0px !important;
}

/* ==>>> Select <<<== */

/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  color: #6750A4 !important;
} */

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  /* border-color: pink !important; */
  color: #6750A4 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
  border-color: #6750A4 !important;
}

::file-selector-button {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiOutlinedInput-input:focus {
  border-color: #6750A4 !important;
  color: #6750A4 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #6750A4 !important;
}

.MuiInputBase-input {
  padding: 0px 14px !important;
  height: 40px !important;
  line-height: 40px !important;
}

/* ==>>> Firm <<<== */

.firm_card {
  position: relative;
  background: #FEF8FC;
  border: 1px solid #CBC4CF;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  z-index: 0;
}

.firm_card_action {
  position: absolute;
  right: 0px;
  top: 15px;
  z-index: 5;
}

.firm_card_detail {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #E8E0EB;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.firm_card_label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #645A70;
  margin: 0px;
}

.firm_card_value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7B757F;
  margin: 0px;
}

.MuiDataGrid-columnHeadersInner {
  background: linear-gradient(0deg, rgba(111, 75, 167, 0.11), rgba(111, 75, 167, 0.11)), #FEF8FC !important;
}

.MuiDataGrid-virtualScrollerRenderZone {
  background: linear-gradient(0deg, rgba(111, 75, 167, 0.05), rgba(111, 75, 167, 0.05)), #FEF8FC !important;
}

.input_error {
  color: red;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_header img {
  cursor: pointer;
}

.MuiOutlinedInput-root {
  padding: 0px 18px 0px 0px !important;
}

.delete_modal_main {
  text-align: center;
}

.delete_modal_main h1 {
  margin: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #6750A4 !important;
}

.MuiTab-root.Mui-selected {
  color: #6750A4 !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.MuiTab-root {
  color: #7A767A !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

/* ===============================================> LOADER <=============================================== */
/* body{
  font-family: 'Hind Guntur', sans-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  overflow: hidden;
} */

.loader_main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  width: 100%;
  background-color: #FFFBFE64;
}

.content {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6750A4;
  animation: bg 1.4s cubic-bezier(0, 0.2, 0.8, 1);
}

@keyframes bg {
  0% {
    background: #000;
  }

  100% {
    background: #6750A4;
  }
}

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading div {
  position: absolute;
  background: #6750A4;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -.7s;
}

@keyframes loading {
  0% {
    top: 40px;
    left: 40px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-footerContainer {
  background-color: #f3eaf5;
}

.MuiTablePagination-toolbar {
  gap: 10px;
}

.MuiTablePagination-toolbar .MuiInputBase-root.MuiInputBase-colorPrimary {
  width: 60px !important;
  min-width: 60px !important;
  margin: 0px !important;
}

.MuiTablePagination-select {
  text-align-last: start !important;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.Toastify__toast {
  min-height: 0 !important;
}

.table_actions {
  display: flex !important;
  width: 120px !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  flex: 1 !important;
}

.table_actions svg {
  cursor: pointer;
}

.group_by_month_card {
  background-color: #FFFBFE;
  padding: 20px;
  border-radius: 10px;
}

.nodata_div {
  text-align: center;
}

.nodata_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #6C48A3;
}

.defaultCheckbox .css-9zcq9s-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.Toastify__close-button {
  padding: 7px 0 !important;
}

.MuiAccordionSummary-content {
  height: 33px;
  align-items: center;
}


.expenses_month_section p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
}

.expenses_month_section .table_actions {
  display: block !important;
}

.expenses_month_section .herader p {
  font-weight: 700;
}

.firmlvoice_details .description {
  font-size: 14px;
  /* width: 127px; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
}


.firmlnvoice_table tbody tr{
  border-bottom: 1px solid #a9a9a9;
}

.firmlnvoice_table thead tr{
  border-bottom: 1px solid #a9a9a9;
}


.firmlnvoice_table  td{
  font-size: 15px;
  border-bottom: 0;
}
.firmlnvoice_table th{
  border-bottom: 0;
}

.firmlnvoice_table thead  th p{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  color:#6750A4;
}

.month_name{
  font-size: 19px !important;
}
@media (max-width:1440px) {
  .month_name{
    font-size: 16px !important;
  }
}